import { uiController } from '@/controllers/uiController'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { BuyTokenPackageDialog } from '../BuyTokenPackageDialog'
import { BuyVipDialogV1 } from '../BuyVipDialogV1'
import ExchangeCodeDialog from '../ExchangeCodeDialog/ExchangeCodeDialog'
import { PaySuccessDialog } from '../PaySuccessDialog'
import { PaySuccessEducationDiaLog } from '../PaySuccessEducationDiaLog'
import style from './CommonDialogLayout.module.less'
import { userService } from '@/services/userService'
import OperationRecordModal from '../OperationRecordModal/OperationRecordModal'
import LoginModal from '../LoginModal/LoginModal'
import { PayQrDialog } from '../PayQrDialog'
import { NewUserDiscount } from '../NewUserDiscount/NewUserDiscount'
import { RegisterCheckPhoneNumber } from '../RegisterCheckPhoneNumber/RegisterCheckPhoneNumber'
import { Link } from 'react-router-dom'

interface CommonDialogLayoutProps {}

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    return (
      <div>
        {/* 支付成功弹窗 */}
        <PaySuccessDialog
          onClose={() => {
            uiController.paySuccessDialogProps = { open: false }
            userService.invokePaySuccessfulListener()
          }}
          {...uiController.paySuccessDialogProps}
        ></PaySuccessDialog>
        {/* 教育优惠支付成功弹窗 */}
        <PaySuccessEducationDiaLog
          {...uiController.paySuccessEducationDialogProps}
          onClose={() => {
            uiController.paySuccessEducationDialogProps = { open: false }
          }}
        ></PaySuccessEducationDiaLog>

        {/* 加量包购买弹窗 */}
        {uiController.buyTokenPackageDialogProps.open && (
          <BuyTokenPackageDialog
            paySuccess={() => {
              // 支付成功
              uiController.paySuccessDialogProps = {
                open: true,
                enableGiftReceiveBtn: false,
              }
            }}
            onClose={(isPaySuccessful) => {
              uiController.buyTokenPackageDialogProps = { open: false }
              if (!isPaySuccessful) {
                // 用户点击关闭
                userService.closePayModalListener?.()
              }
            }}
            {...uiController.buyTokenPackageDialogProps}
          ></BuyTokenPackageDialog>
        )}

        {/* 新版本支付弹窗，仅会员购买 */}
        {uiController.buyVipDialogV1Props.open && (
          <BuyVipDialogV1
            onClose={(isPaySuccessful) => {
              uiController.buyVipDialogV1Props = { open: false }
              if (!isPaySuccessful) {
                // 用户点击关闭
                userService.closePayModalListener?.()
              }
            }}
            paySuccess={(enableReceiveGift) => {
              // 支付成功
              uiController.paySuccessDialogProps = {
                open: true,
                enableGiftReceiveBtn: enableReceiveGift,
              }
            }}
            {...uiController.buyVipDialogV1Props}
          ></BuyVipDialogV1>
        )}
        {/* 通用提示弹窗 */}
        <Modal className={style.commonModal} centered {...uiController.commonModalProps}>
          {uiController.commonModalProps.content}
        </Modal>

        {uiController.exchangeDialogProps.open && (
          <ExchangeCodeDialog
            {...uiController.exchangeDialogProps}
            onClose={() => {
              uiController.exchangeDialogProps = { open: false }
            }}
          ></ExchangeCodeDialog>
        )}

        {/* 操作记录弹窗 */}
        <OperationRecordModal
          open={uiController.operationRecordModalVisible}
          onCancel={() => {
            uiController.operationRecordModalVisible = false
          }}
        ></OperationRecordModal>

        {/* 登录弹窗 */}
        {uiController.loginModalProps.open && (
          <LoginModal
            open={uiController.loginModalProps.open}
            onCancelClick={() => {
              uiController.loginModalProps = { open: false }
            }}
            onLoginSuccess={() => {
              uiController.loginSuccess()
            }}
          />
        )}

        {/* 填写手机号码弹窗 */}
        {uiController.showPhoneNumberModal && (
          <Modal
            open={uiController.showPhoneNumberModal}
            width={528}
            footer={null}
            centered={true}
            title={null}
            maskClosable={false}
            styles={{
              content: {
                borderRadius: '28px',
                padding: '32px 76px 24px',
                background: '#fff',
              },
            }}
            onCancel={() => {
              uiController.showPhoneNumberModal = false
              uiController.openDiscountOrClose()
            }}
          >
            <RegisterCheckPhoneNumber
              onSuccess={() => {
                uiController.showPhoneNumberModal = false
                uiController.openDiscountOrClose()
              }}
              showFooterLink={true}
            ></RegisterCheckPhoneNumber>
          </Modal>
        )}

        {/* 新人福利弹窗 */}
        {uiController.showDiscountModal &&
          uiController.discount &&
          uiController.discountEndTime && (
            <Modal
              open={uiController.showDiscountModal}
              width={1104}
              footer={null}
              centered={true}
              title={null}
              maskClosable={false}
              styles={{
                content: {
                  borderRadius: '28px',
                  padding: '38px 64px 16px',
                  background: 'linear-gradient( 180deg, #F0E6FF 0%, #FFFFFF 310px)',
                },
              }}
              onCancel={() => {
                uiController.showDiscountModal = false
                // vm.openPdf()
                uiController.closeLoginModal()
              }}
            >
              <NewUserDiscount
                styleCSS={{ paddingTop: '0px' }}
                discountEndTime={uiController.discountEndTime}
                discount={uiController.discount}
                onSkipClick={() => {
                  uiController.showDiscountModal = false
                  // vm.openPdf()
                  uiController.closeLoginModal()
                }}
                onJoinClick={async () => {
                  uiController.showPayQr = true
                }}
              />
            </Modal>
          )}

        {/* 支付二维码弹窗 */}
        {uiController.showPayQr && uiController.discount && (
          <PayQrDialog
            open={uiController.showPayQr}
            plan={uiController.discount}
            source={`new_user_discount_buy`}
            paySuccess={() => {
              // 支付成功 关闭弹窗
              uiController.showPayQr = false
              uiController.showDiscountModal = false
              // 支付成功弹窗
              uiController.paySuccessDialogProps = {
                open: true,
                enableGiftReceiveBtn: true,
                onClose: () => {
                  uiController.paySuccessDialogProps = { open: false }
                  // vm.openPdf()
                  uiController.closeLoginModal()
                },
              }
            }}
            onClose={() => {
              uiController.showPayQr = false
            }}
          ></PayQrDialog>
        )}
      </div>
    )
  }
)

export default CommonDialogLayout
