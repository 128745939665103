import { toastController } from '@/controllers/toastController'
import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { MAX_ERROR_COUNT, getLoginParams } from '@/pages/LoginPage'
import { userService } from '@/services/userService'
import { Modal } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import style from './LoginModal.module.less'

interface LoginModalProps {
  open: boolean
  onCancelClick: () => void
  onLoginSuccess: () => void
}

const LoginModal = observer((props: LoginModalProps) => {
  const { open, onCancelClick, onLoginSuccess } = props

  const [qrCode, setQrCode] = useState('')
  const pageUnload = useRef<boolean>(false)
  const checkErrorCount = useRef<number>(0)

  useMount(async () => {
    try {
      const params = getLoginParams()
      const { sceneId, url } = await api.login.getLoginQrCode(params)
      setQrCode(url)
      checkLoginStatus(sceneId)
    } catch (error) {
      toastController.error('登录二维码获取失败，请稍后重试或联系客服')
    }
  })

  useUnmount(() => {
    pageUnload.current = true
  })

  const checkLoginStatus = async (sceneId: string) => {
    if (pageUnload.current) {
      return
    }
    try {
      const { status } = await api.login.checkLoginStatus(sceneId)
      if (status === 'no_scan') {
        await commonUtils.asyncDelay(1 * 1000)
        checkLoginStatus(sceneId)
      } else {
        /** 如果登录用过 inviteCode,则在登录成功后，清除本地的 inviteCode */
        if (storageHelper.get(['inviteCode']).inviteCode) {
          storageHelper.set({ inviteCode: '' })
        }
        // 登录成功 清理 bdUrl/bdVid
        storageHelper.set({ bdUrl: undefined })
        storageHelper.set({ bdVid: undefined })
        // 登录成功 清理 qhclickid
        storageHelper.set({ qhclickid: undefined })
        toastController.success('登录成功')
        await userService.updateUserInfo()
        try {
          commonUtils.sendExtensionMessage('markAuth', {
            isRegister: false,
          })
        } catch (error) {
          //
        }
        onLoginSuccess()
      }
    } catch (error) {
      console.log('error', error)
      checkErrorCount.current += 1
      if (checkErrorCount.current < MAX_ERROR_COUNT) {
        await commonUtils.asyncDelay(1 * 1000)
        checkLoginStatus(sceneId)
      } else {
        toastController.error('登录状态检测失败，请刷新页面重试或联系客服')
      }
    }
  }

  return (
    <Modal
      width='715px'
      maskClosable={false}
      wrapClassName={classNames(style.loginModal)}
      open={open}
      centered={true}
      mask={true}
      footer={null}
      getContainer={false}
      onCancel={() => onCancelClick()}
    >
      <div className={classNames(style.loginContent)}>
        <img
          className={classNames(style.img)}
          src={commonUtils.getStaticFile('pdf/icon__login-left.png')}
        />

        <div className={classNames(style.right)}>
          <div className={classNames(style.title)}>
            <img
              className={classNames(style.wx)}
              src={commonUtils.getStaticFile('pdf/icon__wx.png')}
            />
            <p className={classNames(style.wxText)}>请使用微信扫码</p>
          </div>

          <div className={classNames(style.qrWrap)}>
            {qrCode ? (
              <img className={style.qrCode} src={qrCode} key='qrCode' />
            ) : (
              <img
                key='loading'
                className={style.loadingIcon}
                src='https://assets.weibanzhushou.com/web/pt-web/loading.0e6e0f46d750.gif'
              />
            )}
          </div>
          <p className={classNames(style.info)}>
            微信扫码关注「会译·对照式翻译」公众号，即可登录，无需注册
          </p>
          <p className={classNames(style.info)} style={{ marginTop: '8px' }}>
            扫码即同意
            <Link
              className={style.link}
              target='_blank'
              to={
                'https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.2e0d8664cd55.pdf'
              }
            >
              《用户协议》
            </Link>
            和{' '}
            <Link
              className={style.link}
              target='_blank'
              to='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%96.bbb180e220e9.pdf'
            >
              《隐私协议》
            </Link>
          </p>
        </div>
      </div>
    </Modal>
  )
})

export default LoginModal
