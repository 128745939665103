import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { envHelper } from '@/helpers/envHelper'
import { storageHelper } from '@/helpers/storageHelper'
import { PRO_BENEFITS, PRO_V_BENEFITS } from '@/pages/PricingPage/pricingConsts'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { ee } from '../eventEmitter/eventEmitter'
import { translateService, TranslateServiceType } from '../translateService'
import { TranslateServiceConsts } from '../translateService/consts'
import { BuyVipDialogV1Props } from '@/components/BuyVipDialogV1'
const PRO_V_AGREE_TYPES = ['pro+_quarter_subscription', 'pro+_month_subscription']
const PRO_AGREE_TYPES = ['quarter_subscription', 'month_subscription']
type Locale = TranslateServiceType.Locale
type LocaleWithAutoDetect = TranslateServiceType.LocaleWithAutoDetect
// 提高PDF最大页数为200的用户白名单
const WHITE_LIST_FOR_200_MAX_PDF_PAGE = ['850316974', '596382741']
// 提高PDF最大页数为500的用户白名单
const WHITE_LIST_FOR_500_MAX_PDF_PAGE = ['534627089']

type PaySuccessListener = () => void

class UserService {
  @observable userData: ServerDataTypes.UserInfo | null = null

  // pdf 相关配置
  @observable pdfSourceLang: LocaleWithAutoDetect = TranslateServiceConsts.AUTO_DETECT_LANG
  // eslint-disable-next-line max-len
  @observable pdfTargetLang: Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  // onlineTranslation 相关配置
  @observable onlineTranslationSourceLang: LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  // eslint-disable-next-line max-len
  @observable onlineTranslationTargetLang: Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  @observable canScroll = false
  @observable dragPdfModal = false
  @observable initialed = false

  @observable version = '1.3.11'
  @observable updateDate = '2023-12-12'
  @observable downloadUrl = 'https://cdn.yiban.io/extensions/会译·对照式翻译_1_3_11.zip'
  @observable hy360DownloadUrl: string =
    'https://download.chrome.360.cn/ext/prod-dgeiaiglmhdhajbpfbmajaajdlfdinpi-f4744b86aadc5454004c56566d9ab927.crx'

  @observable selectedPdfFromHome: { hash: string; file: File } | null = null
  // 在购买pro,pro+ 的弹窗支付成功，并且关闭支付成功弹窗时，触发的监听
  paySuccessfulListener: PaySuccessListener[] = []

  @observable localeList: TranslateServiceType.LocaleList = []
  // 图片翻译配置
  @observable imgSourceLang: TranslateServiceType.LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  @observable imgTargetLang: TranslateServiceType.Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )
  // 在线翻译配置
  @observable onlineSourceLang: TranslateServiceType.LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  @observable onlineTargetLang: TranslateServiceType.Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  // 用户手动关闭购买pro,pro+弹窗的监听
  closePayModalListener: (() => void) | undefined = undefined

  constructor() {
    makeObservable(this)
    this.init()
  }

  @computed get dragTipVisibility() {
    return this.canScroll && this.dragPdfModal
  }

  @computed get isLogin() {
    return !!this.userData
  }

  @computed get vipVersion() {
    if (!this.userData) {
      return 'free'
    }
    return this.userData.versionData.version
  }

  @computed get maxPDFPage() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version },
      inviteCode,
    } = this.userData!
    if (version === 'free') {
      return 20
    }
    if (WHITE_LIST_FOR_500_MAX_PDF_PAGE.includes(inviteCode)) {
      return 500
    }
    if (version === 'pro+') {
      return parseInt(PRO_V_BENEFITS.pdfMaxPage)
    }
    if (WHITE_LIST_FOR_200_MAX_PDF_PAGE.includes(inviteCode)) {
      return 200
    }
    return parseInt(PRO_BENEFITS.pdfMaxPage)
  }

  @computed get totalPdfUploadCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfUploadTotalCount },
    } = this.userData!
    return chatPdfUploadTotalCount
  }

  @computed get totalChatCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfChatTotalCount },
    } = this.userData!
    return chatPdfChatTotalCount
  }

  @computed get remainPdfUploadCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfUploadRemainCount },
    } = this.userData!
    return chatPdfUploadRemainCount
  }

  @computed get remainPdfChatCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfChatRemainCount },
    } = this.userData!
    return chatPdfChatRemainCount
  }

  @computed get remainImageTranslateCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { picTranslateRemainCount },
    } = this.userData!

    return picTranslateRemainCount
  }

  @computed get picTranslateTotalCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { picTranslateTotalCount },
    } = this.userData!
    return picTranslateTotalCount
  }

  // 翻译 token 剩余次数
  @computed get remainTranslateCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version, freeAiTranslateCount, proAiTranslateCount, tokenBoosterPackageCount },
    } = this.userData!
    if (version === 'free') {
      return freeAiTranslateCount
    }
    return proAiTranslateCount + tokenBoosterPackageCount
  }

  @computed get totalTranslateCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version, totalFreeAiTranslateCount, totalProAiTranslateCount },
    } = this.userData!
    if (version === 'free') {
      return totalFreeAiTranslateCount
    }
    return totalProAiTranslateCount
  }

  @computed get alipayAgree() {
    if (!this.isLogin) {
      return null
    }
    const {
      versionData: { version, alipayAgree, agreeType },
    } = this.userData!
    if (version === 'free' || !alipayAgree) {
      return false
    }
    if (
      (version === 'pro+' && PRO_V_AGREE_TYPES.includes(agreeType)) ||
      (version === 'pro' && PRO_AGREE_TYPES.includes(agreeType))
    ) {
      return true
    }
    return false
  }

  @computed get sourceLanguageOptions() {
    return [
      {
        locale: TranslateServiceConsts.AUTO_DETECT_LANG,
        localName: '自动检测',
        name: '自动检测',
      },
    ]
      .concat(this.localeList)
      .map((item) => {
        return {
          label: item.localName,
          value: item.locale,
        }
      })
  }

  @computed get targetLanguageOptions() {
    return [...this.localeList].map(({ locale, localName }) => {
      return {
        label: localName,
        value: locale,
      }
    })
  }

  public async init() {
    this.updateSEMKey()
    this.initStorageData()
    await this.updateUserInfo()
    this.updateVersionInfo()
    this.initLocaleList()
  }

  public setPdfLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.pdfSourceLang = lang
      storageHelper.set({ pdfSourceLang: lang })
    } else {
      this.pdfTargetLang = lang
      storageHelper.set({ pdfTargetLang: lang })
    }
  }

  public setImgLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.imgSourceLang = lang
      storageHelper.set({ imgSourceLang: lang })
    } else {
      this.imgTargetLang = lang
      storageHelper.set({ imgTargetLang: lang })
    }
  }

  public setOnlineLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.onlineSourceLang = lang
      storageHelper.set({ onlineSourceLang: lang })
    } else {
      this.onlineTargetLang = lang
      storageHelper.set({ onlineTargetLang: lang })
    }
  }

  /** 主动(手动)登出的情况 */
  public async logout() {
    await api.user.logout()
    this.userData = null
  }

  public async updateUserInfo() {
    try {
      const { userData } = await api.user.getUserInfo()
      console.log('get user info: ', userData)
      runInAction(() => {
        this.userData = userData
      })
      // storageHelper.set({ userData })
    } catch (error) {
      runInAction(() => {
        this.userData = null
      })
      // storageHelper.set({ userData: null })
    }
    runInAction(() => {
      this.initialed = true
    })
    ee.emit('userServiceInitialed', {})
  }

  public openLoginTab() {
    window.open(`/login?redirectUrl=${encodeURIComponent(window.location.href)}`)
  }

  public openPricingTab(type: 'buy' | 'upgrade' | 'renew' | 'wordPack' | 'jump', from: string) {
    if (type === 'jump') {
      window.open(`/pricing?from=${from}`, '_blank')
      return
    }
    const url = `/pricing?type=${type}&from=${from}&v=${
      this.vipVersion === 'free' ? 'pro' : 'pro+'
    }`
    window.open(url, '_blank')
  }

  public openOptionPage() {
    if (commonUtils.isInstalledExtension()) {
      commonUtils.sendExtensionMessage('openOptionPage', {})
    } else {
      window.open('https://huiyiai.net/install')
    }
  }

  public trackUserRecord(actionType: string) {
    const token = commonUtils.genVisitorTokenStr()
    try {
      api.user.postVisitorRecord({ token, actionType })
    } catch (error) {
      //do nothing
    }
  }

  private async updateVersionInfo() {
    try {
      const { chromeVersion, hy360DownloadUrl, extensionUpdateTime } = await api.getHuiyiUrlConfig()
      const hyChromeDownloadUrl = `https://cdn.yiban.io/extensions/会译·对照式翻译_${chromeVersion.replace(
        /\./g,
        '_'
      )}.zip`
      this.version = chromeVersion
      this.downloadUrl = hyChromeDownloadUrl
      this.hy360DownloadUrl = hy360DownloadUrl
      const updateDate = dayjsHelper.formatTime(extensionUpdateTime * 1000, 'YYYY-MM-DD')
      this.updateDate = updateDate
      console.log('version info', {
        chromeVersion,
        hyDownloadUrl: hyChromeDownloadUrl,
        hy360DownloadUrl,
        updateDate,
      })
      ee.emit('versionInfoChanged', {
        downloadUrl: hyChromeDownloadUrl,
        updateDate,
        hy360DownloadUrl,
        version: chromeVersion,
      })
    } catch (error) {
      // do nothing
    }
  }

  public updateSEMKey() {
    try {
      const { utm, kword, utm_term, bd_vid, bd_url, qhclickid, referer } = commonUtils.getUrlArgs()
      if (utm) {
        storageHelper.set({ utm })
      }
      if (
        document.referrer &&
        document.referrer.indexOf('ad.huiyi') === -1 &&
        document.referrer.indexOf('localhost') === -1
      ) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: document.referrer })
        }
      }
      if (referer) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: referer })
        }
      }
      if (kword) {
        const decodedKword = decodeFromGb2312(kword)
        storageHelper.set({ kword: decodedKword })
      }

      const storageReferer = storageHelper.get(['referer']).referer || ''
      if (storageReferer) {
        const refererChannel = encodeURIComponent(new URL(storageReferer).hostname)
        // www.so.com
        const { q } = commonUtils.getUrlArgs(storageReferer)
        if (refererChannel === 'www.so.com' && q) {
          storageHelper.set({ kword: q })
        }
        // cn.bing.com
        if (refererChannel === 'cn.bing.com' && utm_term) {
          storageHelper.set({ kword: utm_term })
        }
      }

      // 360 ocpc 投放参数
      if (qhclickid) {
        storageHelper.set({ qhclickid: qhclickid })
      }

      // 并不意味着 location.href 中有 bd_vid 就是从百度投放来的
      // 还有可能是从自定义投放页面来的 此时会携带 bd_url
      if (bd_vid || bd_url) {
        // 优先 bd_url 其次 location.href
        if (bd_url) {
          const bd_url_vid = commonUtils.getQueryFromUrl('bd_vid', bd_url)
          if (bd_url_vid) {
            storageHelper.set({ bdUrl: bd_url, bdVid: bd_url_vid })
            return
          }
        }
        storageHelper.set({ bdUrl: window.location.href, bdVid: bd_vid })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  public doPay(data: {
    wordPack?: boolean
    version?: 'pro' | 'pro+'
    paySuccess?: BuyVipDialogV1Props['paySuccess']
    source: string
  }) {
    const { wordPack, version, source, paySuccess } = data
    if (wordPack) {
      uiController.buyTokenPackageDialogProps = {
        source: `${source}_v1`,
        open: true,
      }
    } else {
      uiController.buyVipDialogV1Props = {
        open: true,
        version,
        source: `${source}_v1`,
        paySuccess,
      }
    }
    userService.trackUserRecord(wordPack ? 'buyVipDialogV1_token' : 'buyVipDialogV1_vip')
  }

  public addPaySuccessfulListener(listener: PaySuccessListener) {
    this.paySuccessfulListener.push(listener)
  }

  public removePaySuccessfulListener(listener: PaySuccessListener) {
    const index = this.paySuccessfulListener.findIndex((item) => item === listener)
    if (index !== -1) {
      this.paySuccessfulListener.splice(index, 1)
    }
  }

  public invokePaySuccessfulListener() {
    this.paySuccessfulListener.forEach((listener) => {
      listener()
    })
  }

  public async openPdfPage(fileUrl = '', tab: 'pdf' | 'favorite' = 'pdf') {
    const url = 'webapp/pdf'

    const urlWithParams = commonUtils.genUrl(`${envHelper.webHost}/${url}`, {
      fileUrl,
      tab,
    })

    window.open(urlWithParams, '_blank')
  }

  private initLocaleList() {
    translateService.getLocaleList().then((data) => {
      this.localeList = data
    })
  }

  private initStorageData() {
    const {
      dragPdfModal,
      pdfSourceLang,
      pdfTargetLang,
      imgSourceLang,
      imgTargetLang,
      onlineSourceLang,
      onlineTargetLang,
    } = storageHelper.get([
      'pdfSourceLang',
      'pdfTargetLang',
      'dragPdfModal',
      'imgSourceLang',
      'imgTargetLang',
      'onlineSourceLang',
      'onlineTargetLang',
    ])
    const getSourceLang = (lang: TranslateServiceType.LocaleWithAutoDetect) => {
      if (lang === 'auto-detect') {
        return TranslateServiceConsts.AUTO_DETECT_LANG
      }
      return lang
    }
    pdfSourceLang && (this.pdfSourceLang = getSourceLang(pdfSourceLang))
    pdfTargetLang && (this.pdfTargetLang = pdfTargetLang)
    imgSourceLang && (this.imgSourceLang = getSourceLang(imgSourceLang))
    imgTargetLang && (this.imgTargetLang = imgTargetLang)
    onlineSourceLang && (this.onlineSourceLang = getSourceLang(onlineSourceLang))
    onlineTargetLang && (this.onlineTargetLang = onlineTargetLang)
    this.dragPdfModal = dragPdfModal ?? true
  }

  // 记录学习模式埋点
  public recordLearningMode(
    learningActionType:
      | 'click_header_learning'
      | 'click_desc_learning'
      | 'click_banner_learning'
      | 'click_learning_page_upgrade'
  ) {
    this.trackUserRecord(learningActionType)
  }
}

export const userService = new UserService()
