interface Benefits {
  title: string
  desc: string
  proStr?: string
  freeStr: string
  proVStr?: string
  neice?: boolean
  isToken?: boolean
  iconfont: string
  key: string
  proFuncList?: string[]
  proVFuncList?: string[]
}

interface BenefitsInfo {
  tokenCount: string
  pdfUploadCount: string
  pdfChatCount: string
  pdfMaxPage: string
  imageCount: string
}

export const PRO_BENEFITS: BenefitsInfo = {
  tokenCount: '1,000,000',
  pdfUploadCount: '50',
  pdfChatCount: '1000',
  pdfMaxPage: '100',
  imageCount: '100',
}
export const PRO_V_BENEFITS: BenefitsInfo = {
  tokenCount: '3,000,000',
  pdfUploadCount: '120',
  pdfChatCount: '2500',
  pdfMaxPage: '400',
  imageCount: '300',
}

export const TIPS_CONTENT = [
  '1.当您购买过多重会员套餐时，将会优先消耗Pro+会员有效天数，当Pro+会员有效天数消耗完毕后，再消耗Pro会员有效天数',
  '2. 当Pro+会员到期后，剩余的Pro会员有效天数小于1个月，您的各项权益上限仍按1个月计算',
]

// const freeIcon =
//   'https://assets.weibanzhushou.com/web/pt-web/pricing-page_equity-icon-10.1c825c1f1428.png'
// const proIcon =
//   'https://assets.weibanzhushou.com/web/pt-web/pricing-page_equity-icon-13.f787bd25d3dc.png'

export const BENEFITS_LIST: Benefits[] = [
  {
    title: 'Token 数',
    desc: '部分翻译功能将消耗 Token 数量',
    proVStr: `每月 ${PRO_V_BENEFITS.tokenCount} token`,
    isToken: true,
    freeStr: '共赠送 5,000 token',
    proStr: `每月 ${PRO_BENEFITS.tokenCount} token`,
    iconfont: 'icon-line-global_Token',
    key: 'token',
  },
  {
    title: 'PDF 上传文件数',
    desc: '上传后支持 PDF 全文翻译和 ChatPDF 能力',
    proStr: `${PRO_BENEFITS.pdfUploadCount}篇/月`,
    freeStr: '1篇',
    proVStr: `${PRO_V_BENEFITS.pdfUploadCount}篇/月`,
    neice: true,
    isToken: false,
    iconfont: 'icon-line-global_Upload',
    key: 'pdfUpload',
  },
  {
    title: 'PDF 最大页数限制',
    desc: '单篇 PDF 不得超过最大页数的限制',
    proStr: `${PRO_BENEFITS.pdfMaxPage}页/PDF`,
    freeStr: '20页/PDF',
    proVStr: `${PRO_V_BENEFITS.pdfMaxPage}页/PDF`,
    neice: true,
    isToken: false,
    iconfont: 'icon-line-global_limitation',
    key: 'pdfMaxPage',
  },
  {
    title: 'ChatPDF 询问次数',
    desc: '基于 PDF 的 Chat 对话中，总共对话次数',
    proStr: `${PRO_BENEFITS.pdfChatCount}次/月`,
    freeStr: '5次',
    proVStr: `${PRO_V_BENEFITS.pdfChatCount}次/月`,
    neice: true,
    isToken: false,
    iconfont: 'icon-line-global_ask2',
    key: 'chat',
  },
  {
    title: '网页翻译',
    desc: '全文对照，双语、原/译文随时切换阅读模式',
    proStr: '消耗 token',
    freeStr: '消耗 token',
    proVStr: '消耗 token',
    isToken: false,
    iconfont: 'icon-line-global_web',
    key: 'web',
  },
  {
    title: '即时翻译',
    desc: '填写词/句，即时翻译浏览器外文本',
    proStr: '消耗 token',
    freeStr: '每天 100 次',
    proVStr: '消耗 token',
    isToken: false,
    iconfont: 'icon-line-global_instant',
    key: 'realtime',
  },
  {
    title: '划词翻译',
    desc: '一键选词，快捷翻译，满足多场景翻译需求',
    proStr: '消耗 token',
    freeStr: '消耗 token',
    proVStr: '消耗 token',
    isToken: false,
    iconfont: 'icon-line-global_dictionary',
    key: 'select',
  },
  {
    title: '图片翻译',
    desc: '翻译后的图片自动取代网页原始图片',
    proStr: `${PRO_BENEFITS.imageCount}张/月`,
    freeStr: '5张/月',
    proVStr: `${PRO_V_BENEFITS.imageCount}张/月`,
    isToken: false,
    iconfont: 'icon-line-global_picture',
    key: 'img',
  },
  {
    title: '悬停翻译',
    desc: '悬停翻译任意段落，自主掌握翻译进程',
    proStr: '消耗 token',
    freeStr: '消耗 token',
    proVStr: '消耗 token',
    isToken: false,
    iconfont: 'icon-line-global_hover',
    key: 'hover',
  },
  {
    title: '视频翻译',
    desc: '双语字幕，提升观看体验，快速获取信息',
    proStr: '消耗 token',
    freeStr: '消耗 token',
    proVStr: '消耗 token',
    isToken: false,
    iconfont: 'icon-line-global_video',
    key: 'video',
  },
  {
    title: '学习模式',
    desc: '全网页自动高亮生词，把网页变成“课堂”',
    proStr: '',
    freeStr: '7天免费试用',
    proVStr: '',
    isToken: false,
    iconfont: 'icon-a-line-global_instantbeifen2',
    key: 'learning',
    proFuncList: ['四六级', '雅思', '托福', '考研英语', 'GRE', 'SAT'],
    proVFuncList: ['四六级', '雅思', '托福', '考研英语', 'GRE', 'SAT'],
  },
]

interface QuestionInfo {
  title: string
  expand?: boolean
  content: {
    text?: string
    imgUrl?: string
    style?: { [k: string]: string | number }
  }[]
}
export const QUESTIONS: QuestionInfo[] = [
  {
    title: '我可以免费试用吗？',
    content: [
      {
        text: '可以，任何注册过会译的用户都会获得免费试用的资格，我们将提供5000的token数以供用户体验我们的产品',
      },
    ],
  },
  {
    title: 'Pro会员提供的AI翻译服务是无限量的吗？',
    content: [
      {
        text: '不是无限量的，我们给Pro会员提供 100W/月的 token数。根据我们会员的过往使用情况，大多数情况下你不需要为token数不够而担心，每月的token数是足够你高频的使用网站翻译的。如果你有更大量的需求，可以通过yexiumin@nightingales.cn联系我们，我们会重新评估你的使用情况，帮助你解决相应问题',
      },
    ],
  },
  {
    title: 'Pro会员的token数什么时候刷新？',
    content: [
      {
        text: '如果你购买的是年费/季度会员，我们将会在有效期内的每个月刷新token数，刷新时间与购买日期相同。比如，你购买时间为10月13日，则将会在11月13日0点刷新token数。若购买日期为月份最后一天的31、30、28特殊日期，我们将会在月末最后一天进行刷新',
      },
    ],
  },
  {
    title: '连续包月、连续包季和连续包年服务可以取消么？',
    content: [{ text: '可以随时取消，可在“设置-自动续费”中管理你的Pro会员计划' }],
  },
  {
    title: '购买后是否可以开发票？',
    content: [
      {
        text: '可以的，你可以通过邮件（fuchunming@nightingales.cn）告诉我们你的发票需求；或者直接添加下方二维码，直接联系我们的企业微信',
      },
      {
        imgUrl:
          'https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.126a016dd9f4.jpeg',
        style: { width: 200 },
      },
    ],
  },
]

export const VIP_GIFT_LIST: {
  icon: string
  name: string
  desc: string
  slogen: string
  bigIcon: string
  price: string
  planBIcon: string
}[] = [
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__yiban.b0d3ebe734bc.png',
    name: '壹伴',
    desc: '30天标准版会员',
    slogen: '更好用的微信编辑器，但不止于此',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__yiban-big.7c1a492b83d5.png',
    price: '原价¥20.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__yiban.ec7fdf135ba5.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__chuagnketie.ff3ed30159b7.png',
    name: '创客贴',
    desc: '7天VIP',
    slogen: '极简的在线平面设计工具',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__chuangketie-big.0e28b74f7852.png',
    price: '原价¥39.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__chuangketie.a2215620736c.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__process_on.99c37c669f83.png',
    name: 'process on',
    desc: '7天体验码',
    slogen: '专业在线作图工具和知识分享社区',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__processon-big.d377f6791e56.png',
    price: '原价¥19.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__processon.243bbd7ddd65.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__modao.26f26a21e716.png',
    name: '墨刀',
    desc: '30天个人版VIP',
    slogen: '一款强大且易用的原型设计与协同工具',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__modao-big.07e73eeb6d5e.png',
    price: '原价¥30.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__modao.9d972dddf397.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__islide.6359265d43e2.png',
    name: 'islide',
    desc: '30天会员',
    slogen: '提升90%的PPT制作效率 ',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__islide-big.2b1f0fb7673c.png',
    price: '原价¥20.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__islide.e352c4f98970.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__zhitujianli.a55ae81b26db.png',
    name: '职徒简历',
    desc: '30天VIP',
    slogen: '智能简历制作神器',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__zhitujianli-big.76d006f124ff.png',
    price: '原价¥18.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__zhitujianli.77034224565b.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__MAKA.9d71d37ba010.png',
    name: 'MAKA',
    desc: '7天高级会员',
    slogen: '海量模板做出好设计',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__maka-big.6f2a08ddafdf.png',
    price: '原价¥45.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__maka.a0cc4a61b1bd.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__tuguaisou.ce9715f072b5.png',
    name: '图怪兽',
    desc: '7天VIP',
    slogen: '100w+可商用模板，在线编辑快速出图',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__tuguaisou-big.dbf0983521e0.png',
    price: '原价¥59.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__tuguaisou.4e9f85fa6b0e.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__fengui.42c79951ee8f.png',
    name: '分轨',
    desc: '30天使用权',
    slogen: '上传文件，即刻分离人声和伴奏',
    bigIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift__fengui-big.b536e7d58edd.png',
    price: '原价¥49.00',
    planBIcon:
      'https://assets.weibanzhushou.com/web/pt-web/gift-icon_gift-b__fengui.73a52aea56f4.png',
  },
]
