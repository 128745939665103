/* eslint-disable @typescript-eslint/member-ordering */

import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { PRO_BENEFITS, PRO_V_BENEFITS, VIP_GIFT_LIST } from '@/pages/PricingPage/pricingConsts'
import { BuyVipServiceTypes as Types } from './serviceTypes'
type StorageData = Types.StorageData

const FUNC_LIST: ServerDataTypes.BuyVipDialogExtraData['featureList'] = [
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/icon__learning.png',
    label: '学习模式',
    key: 'learning',
    intro:
      '打开任意网页，快捷键“Ctrl+Shift+X”或打开悬浮球-学习模式-开启生词高亮，即可进入学习模式；帮您把网页变课堂，轻松解锁学习新姿势，让生词掌握更轻松，让网页浏览更有趣。',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/icon__learning.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__web.1141053cefa0.png',
    label: '网页对照翻译',
    key: 'web',
    intro:
      '原文和译文同时呈现在同一页面上，无论你正在阅读的文本是中文还是英文，或是其他小语种，会译都能提供准确的翻译结果，让你能更好地理解文本内容。',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func_web-v1.3d6dde7fd0eb.png',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__pdf.1cafc431dc77.png',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__pdf-v1.43216515e53c.png',
    label: 'PDF翻译',
    key: 'pdf',
    intro:
      '基于 AI 模型的 PDF 翻译，自动识别文本所在领域，并保持原文样式和排版，用词准确，行文通顺。还可以将翻译内容一键分享好友阅读。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__chatPDF.fa3acbd842b4.png',
    label: 'Chat PDF',
    key: 'chat',
    icon1:
      'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__chatPDF-v1.56c2ca09f7be.png',
    intro:
      '基于 PDF 文档的专属 Chat 模型能力，你可以通过 Chat 提问交流，高效获取该 PDF 文档的摘要总结内容和关键问题答案。是你在科学论文、行业报告、法律文档、财务资料等复杂文档阅读的高效帮手。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__select.18660c7cab30.png',
    label: '划词翻译',
    key: 'select',
    icon1:
      'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__select-v1.6526108a70a3.png',
    intro:
      '遇到不认识的单词，你可以直接在网页上划词，就能快速知道翻译结果，省去了复制粘贴再查询的繁琐步骤，使用非常方便。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__realtime.5957589d322d.png',
    label: '即时翻译',
    icon1:
      'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__realtime-v1.408fc6ee505f.png',
    key: 'realtime',
    intro:
      '在浏览器任何页面打开我们的 Popup，填写词/句，即可快速查询翻译结果，即时翻译浏览器外的文本内容。还可以自定义翻译指令，翻译的同时有针对的优化文案内容。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__hover.d316a8fc9832.png',
    label: '悬停翻译',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__hover_v1.f975bf6950e2.png',
    key: 'hover',
    intro:
      '浏览网页时，只需将鼠标悬停在需要翻译的段落上，即可获得翻译结果，这种方式适合阅读某个段落和句子的情况，精准获取你所需要的信息。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func__img.d5e8ce7811a4.png',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func__image-v1.f86bd0b443eb.png',
    label: '图片翻译',
    key: 'pic',
    intro:
      '浏览网页的过程中，在需要翻译的图片右下角点击我们的翻译图标，翻译后的图片就会自动取代网页原始图片，带来流畅的翻译阅读体验。',
  },
  {
    icon: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_func_video.012a19689681.png',
    icon1: 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_func_video-v1.594544b7be7f.png',
    label: '视频翻译',
    key: 'video',
    intro:
      '在 YouTube 网站中，你可以借助视频 AI 翻译功能，在感兴趣的视频上，直接看到精准的双语字幕。帮你快速的理解视频内容，无障碍观看视频。（后续会支持更多的主流视频网站，让你享受更流畅的视听体验。）',
  },
]
class BuyVipService {
  public static serviceName = 'BuyVipService'
  protected storagePrefix = ''
  protected defaultStorageValues: StorageData = {}
  protected onInit() {}

  public async createOrder(data: PostDataTypes.OrderCreateParams) {
    return api.pricing.createOrder(data)
  }

  public async pollOrderStatus(orderId: string) {
    return api.pricing.pollOrderStatus(orderId)
  }

  public async list(newUser?: 1) {
    const { planList, tokenPlanList, proPlusPlanList, eduPlanList } = await api.pricing.list(
      newUser
    )
    const _planList = commonUtils.formatPlanList(planList)
    const { matchType, dataList } = await this.getUserWelfare(newUser)
    if (!newUser) {
      if (matchType === 'exchange_code') {
        const { timeType, count } = dataList[0]
        _planList.forEach((plan, i) => {
          if (plan.timeType === timeType && plan.count === count) {
            _planList[i] = dataList[0]
          }
        })
      }
    }
    return { planList: _planList, tokenPlanList, matchType, proPlusPlanList, eduPlanList }
  }

  public async getVipGiftReceiveQr() {
    return api.pricing.getVipGiftReceiveQr()
  }

  public async getBuyVipDialogExtraData(): Promise<ServerDataTypes.BuyVipDialogExtraData> {
    try {
      const { data } = await api.pricing.getBuyVipDialogExtraData()
      return data
    } catch (error) {
      return {
        featureList: FUNC_LIST,
        vipGiftList: VIP_GIFT_LIST,
        proBenefits: PRO_BENEFITS,
        proVBenefits: PRO_V_BENEFITS,
      }
    }
  }
  private async getUserWelfare(newUser?: 1): Promise<ServerDataTypes.WelfarePlanData> {
    if (newUser) {
      return { matchType: 'not_match', dataList: [] }
    }
    try {
      const { matchType, dataList } = await api.pricing.getWelfarePlan()
      return { matchType, dataList }
    } catch (error) {
      return { matchType: 'not_match', dataList: [] }
    }
  }
}

export const buyVipService = new BuyVipService()
