import { post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

type TranslateWordType = 'word' | 'paragraph'

interface TranslateResult {
  errMsg: string | null
  wordType: TranslateWordType
  data: {
    // 翻译结果
    transList: Array<string>
    // 音标
    phonetic?: string
    // 词性
    classes?: string
  }
  translateRecordId: number
}

export interface OnlineTranslateOptions {
  text: string
  fromLang: string
  toLang: string
  useType: 'online'
}

export namespace onlineTranslate {
  export function translate(
    data: OnlineTranslateOptions,
    controller?: AbortController
  ): Promise<ApiBaseResult & { data: TranslateResult }> {
    return post('/online/translate', {}, data, undefined, controller)
  }
}
