import { commonUtils } from './commonUtils'
import { storageHelper } from './storageHelper'

class EnvHelper {
  private _oem: OEM_TYPE = 'default'

  public init() {
    try {
      // 尝试取 url 中的 oem 参数
      const urlParams = new URLSearchParams(window.location.search)
      const urlOem = urlParams.get('oem') as OEM_TYPE
      if (urlOem) {
        this._oem = urlOem
        storageHelper.set({ oem: urlOem })
        return
      }
      // 尝试取写入 body 标签上的 oem 属性
      const extensionOem = commonUtils.getExtensionOem()
      if (extensionOem) {
        this._oem = extensionOem
        storageHelper.set({ oem: extensionOem })
        return
      }
      const { oem } = storageHelper.get(['oem'])
      this._oem = oem || 'default'
    } catch (error) {
      // do nothing
    }
  }

  get extensionPrefix() {
    return 'huiyi_webapp'
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get productName() {
    return '会译'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get webHost() {
    return process.env.REACT_APP_WEB_HOST
  }

  get apiHost() {
    return this.webHost
  }

  get apiBasePath() {
    return '/hy_api'
  }

  get oem(): OEM_TYPE {
    return this._oem
  }
}

export const envHelper = new EnvHelper()
